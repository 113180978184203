import React from 'react';
import { Box, Button, Container, Heading, Text, Stack, VStack, HStack, List, ListItem, Flex, Spacer, Image } from '@chakra-ui/react';

// Navbar component
const Navbar = () => (
  <Flex as="nav" align="center" justify="space-between" wrap="wrap" padding="1.5rem" bg="white" color="gray.600">
    <Flex align="center" mr={5}>
      <Image src="/assets/logo.svg" alt="Company Logo" height="40px" />
    </Flex>
    <Spacer />
    <HStack spacing={4}>
      <Button colorScheme="blue" variant="outline">Sign Up</Button>
      <Button colorScheme="blue">Sign In</Button>
    </HStack>
  </Flex>
);

// PricingTier component
const PricingTier = ({ title, price, features, isPopular }) => (
  <Box
    borderWidth="1px"
    borderRadius="lg"
    p={6}
    backgroundColor={isPopular ? "blue.50" : "white"}
    boxShadow={isPopular ? "lg" : "base"}
  >
    <VStack spacing={3} align="stretch">
      <Heading size="md">{title}</Heading>
      <Text fontSize="2xl" fontWeight="bold">${price}/mo</Text>
      <Button colorScheme={isPopular ? "blue" : "gray"}>
        {isPopular ? "Start Free Trial" : "Choose Plan"}
      </Button>
      <List spacing={3}>
        {features.map((feature, index) => (
          <ListItem key={index}>
            {feature}
          </ListItem>
        ))}
      </List>
    </VStack>
  </Box>
);

const LandingPage = () => {
  const pricingTiers = [
    {
      title: "Free",
      price: 0.00,
      features: ["Feature 1", "Feature 2", "Feature 3"],
      isPopular: false
    },
    {
      title: "Pro",
      price: 19.99,
      features: ["All Basic features", "Feature 4", "Feature 5", "Feature 6"],
      isPopular: true
    },
    {
      title: "Wizard",
      price: 39.99,
      features: ["All Pro features", "Feature 7", "Feature 8", "Feature 9", "Feature 10"],
      isPopular: false
    }
  ];

  return (
    <Box bg="gray.50" minHeight="100vh">
      <Navbar />
      <Container maxW="container.xl" py={20}>
        <VStack spacing={12}>
          <Heading as="h1" size="2xl" textAlign="center">
            Choose Your Plan
          </Heading>
          <Text fontSize="xl" textAlign="center">
            Select the perfect plan for your needs
          </Text>
          <Stack direction={["column", "column", "row"]} spacing={8} align="stretch">
            {pricingTiers.map((tier, index) => (
              <PricingTier key={index} {...tier} />
            ))}
          </Stack>
        </VStack>
      </Container>
    </Box>
  );
};

export default LandingPage;