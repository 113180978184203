import React, { useState } from 'react';
import {
  Box, Button, FormControl, FormLabel, Input,
  VStack, Switch, useToast
} from '@chakra-ui/react';
import axios from 'axios';

const API_BASE_URL = 'https://api.traderbff.com/auth';

const AuthForm = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleSignIn = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/signin`, {
        Username: email,
        Password: password
      });

      if (response.status === 200) {
        const bodyData = response.data;
        if (bodyData.Success) {
          console.log('Sign in successful:', bodyData);
          // TODO: Store tokens and user data
          toast({
            title: "Signed in successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          // TODO: Redirect or update app state
        } else {
          throw new Error(bodyData.Message || 'Sign in failed');
        }
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Sign in error:', error);
      toast({
        title: "Sign in failed",
        description: error.message || "An unexpected error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSignUp = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/signup`, {
        Email: email,
        Username: email,
        Password: password
      });

      if (response.data.statusCode === 200) {
        const bodyData = JSON.parse(response.data.body);
        if (bodyData.Success) {
          console.log('Sign up successful:', bodyData);
          // TODO: Store tokens and user data
          toast({
            title: "Account created successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          // TODO: Redirect or update app state
        } else {
          throw new Error(bodyData.Message || 'Sign up failed');
        }
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Sign up error:', error);
      toast({
        title: "Sign up failed",
        description: error.message || "An unexpected error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (isSignUp) {
      await handleSignUp();
    } else {
      await handleSignIn();
    }
    setIsLoading(false);
  };

  return (
    <Box maxWidth="400px" margin="auto">
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </FormControl>
          <FormControl>
            <FormLabel>Password</FormLabel>
            <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="auth-mode" mb="0">
              {isSignUp ? 'Already have an account?' : 'Need to create an account?'}
            </FormLabel>
            <Switch id="auth-mode" onChange={() => setIsSignUp(!isSignUp)} />
          </FormControl>
          <Button type="submit" colorScheme="blue" isLoading={isLoading}>
            {isSignUp ? 'Sign Up' : 'Sign In'}
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default AuthForm;