import React from 'react';
import { Box, Flex, Link as ChakraLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <Box bg="gray.100" py={4}>
      <Flex maxWidth="1200px" margin="auto" justifyContent="space-between" alignItems="center">
        <ChakraLink as={Link} to="/" fontSize="2xl" fontWeight="bold">
          Logo
        </ChakraLink>
        <Box>
          <ChakraLink as={Link} to="/" mr={4}>Home</ChakraLink>
          <ChakraLink as={Link} to="/auth">Sign In / Sign Up</ChakraLink>
        </Box>
      </Flex>
    </Box>
  );
};

export default Header;