import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import AuthForm from './AuthForm';

const Auth = () => {
  return (
    <Box maxWidth="800px" margin="auto" padding={8}>
      <Heading as="h1" size="xl" textAlign="center" mb={8}>
        Welcome
      </Heading>
      <AuthForm />
    </Box>
  );
};

export default Auth;